body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div#root {
  width: 100%;
}

.carousel-item {
  margin-right: 20px; /* Adjust the margin as needed for spacing */
}

.slick-prev, .slick-next {
  background: grey !important;
}

nav.navbar.header {
  padding-left: 3%;
}


.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-container {
  height: 100%;
  padding-left: 0.7%;
  padding-right: 0.7%;
}

.header-container {
  padding: 0.7%;
}


.header {
  background-color:#234087;
  display: flex;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.footer {
  background-color:#234087;
  color: white;
  padding: 20px 0;
  text-align: center;
}

.footer-copyright {
  opacity: 0.75; 
}

@media (max-width: 1200px) {
  .carousel {
    width: 800px !important;
    padding-left: 2% !important;
    padding-right: 2% !important;
  }

  .carousel .slick-arrow{
    display: none !important;
  }

  /* .carousel .slick-list .slick-slide{
    width: 250px !important;
    margin-right: 5px !important;
    margin-left: 5px !important;
  } */
}